<template>
  <div id="app">
    <transition name="fade" @before-enter="beforeEnter" @enter="enter" @leave="leave">
      <router-view/>
    </transition>
  </div>
</template>

<script>
export default {
  methods: {
    beforeEnter(el) {
      el.style.opacity = 0;
    },
    enter(el, done) {
      el.offsetHeight; // trigger reflow
      el.style.transition = 'opacity 0.7s';
      el.style.opacity = 1;
      done();
    },
    leave(el, done) {
      el.style.transition = 'opacity 0.7s';
      el.style.opacity = 0;
      done();
    }
  }
}
</script>

<style>

body {
  background-color: #102542;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #FAF2A1;
  /* overflow-y: hidden; */
}

.green {
  color: #7CDB4F;
}
.btn-green {
  background-color: #7CDB4F;
} 

nav {
  padding: 30px;
}

nav a {
  font-weight: bold;
  color: #2c3e50;
}

nav a.router-link-exact-active {
  color: #42b983;
}
.font-list {
    display: flex;
} 
.font-item {
  margin: 20px;
}
.color-list {
  display: flex;
  flex-wrap: wrap;
}

.color-item {
  width: 50px;
  height: 50px;
  margin: 5px;
  position: relative;
  cursor: pointer;
}

.glass {
  background: rgba( 255, 255, 255, 0.15 );
  /* box-shadow: 0 8px 32px 0 rgba( 31, 38, 135, 0.37 ); */
  backdrop-filter: blur( 4px );
  -webkit-backdrop-filter: blur( 4px );
  border-radius: 20px;
}

.heading {
  @apply font-bold text-3xl sm:text-4xl md:text-[40px] text-white mb-6 relative;
}

.heading::after {
  content: '';
  @apply absolute left-1/2 bottom-[-15px] w-[20%] h-[3px] bg-secondary translate-x-[-50%] transition-all;
}

.step-content {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.material-icons {
  font-size: 24px;
}

</style>