<template lang="">
  <div class="font-inter">
    <!-- BETA INFO -->
    <!-- <div class="w-full sm:text-base sticky top-0 bg-red-500 flex justify-center items-center text-white font-bold z-10">
      Important: As we are in the Beta Phase we recommend using Google Chrome, Mozilla Firefox, Opera, Microsoft Edge.
    </div> -->

    <Menu />

    <!-- HERO SECTION -->
    <div class="relative w-full h-screen overflow-hidden">
      <video preload="auto" loading="lazy" autoplay muted loop class="absolute top-1/2 left-1/2 w-full h-full object-cover transform -translate-x-1/2 -translate-y-1/2">
        <source src="@/assets/LY - Header.mp4" type="video/mp4">
      </video>

      <div class="relative z-10 flex items-center justify-center h-full p-8">
        <div class="text-primary h-[60vh] relative flex items-center justify-center p-8">
          <div class="max-w-screen mx-auto animate-ease-in-out">
            <!-- Single Column -->
            <div class="max-w-lg mx-auto text-center">
              <h1 class="animate-fade-right animate-delay-300 text-5xl xl:text-7xl font-bold tracking-tight text-white pt-4 sm:pt-0">Your brand, ready in minutes</h1>
              <p class="animate-fade-left animate-delay-300 mt-4 text-base sm:text-lg leading-6 text-white font-bold">From Launch to Growth: Logo<span class="text-secondary">Ya</span> Creates Your Branding Assets and Fuels Growth with AI Marketing Tools.</p>
              <div class="mt-8">
                <button onclick="ml('show', 'PaZtC4', true)"
                href="javascript:void(0)" class="animate-jump-in animate-delay-500 ml-onclick-form btn-green text-primary font-bold font-inter border-none rounded-md p-3 sm:p-4">Get an early access</button>
              </div>  
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Testing Gradient Colors-->
    <div class="h-14 bg-gradient-to-b from-darkPrimary to-primary"></div>
    <!-- BENEFITS SECTION -->
    <section class="bg-cover animate-fade-up animate-zoom-in animate-delay-700 flex justify-center pt-20 pb-24 lg:pb-[250px]">
      <!-- Blobs -->
      <img src="../assets/blob.gif" alt="blob" class="absolute top-0 left-5 w-28 h-28 xl:w-40 xl:h-40"/>
      <img src="../assets/blob.gif" alt="blob" class="absolute bottom-5 left-1/2 xl:top-3/4 w-24 h-24 xl:w-32 xl:h-32"/>
      <img src="../assets/blob.gif" alt="blob" class="absolute top-1/2 right-0 xl:right-20 w-20 h-20 xl:w-24 xl:h-24"/>
      <div class="container">
        <div class="flex flex-wrap mx-4">
          <div class="w-full px-4">
            <div class="text-center mx-auto mb-12 lg:mb-20 max-w-[510px]">
              <h2 class="heading">
               Our Benefits
              </h2>
              <span class="font-semibold text-lg text-white mb-2 block">
                Save time, money and headaches with our platform.
              </span> 
            </div>
          </div>
        </div>
        <div class="flex flex-wrap mx-4 gap-y-12">
          <div class="w-full md:w-1/3 lg:w-1/3 px-4">
            <div class="glass items-center p-[20px] md:px-7 xl:px-10 rounded-[20px] shadow-md mb-8 flex flex-col h-full">
              <div class="w-fit p-5">
                <img class="object-contain h-24 w-36" src="../assets/landingImages/time.svg" alt="Time"/>
              </div>
              <h4 class="text-center font-semibold text-3xl green mb-3">
              Save time
              </h4>
              <p class="text-center text-white flex-grow">
              Say goodbye to lengthy design cycles. Get your branding instantly.
              </p>
            </div>
          </div>
          <div class="w-full md:w-1/3 lg:w-1/3 px-4">
            <div class="glass items-center p-[20px] md:px-7 xl:px-10 rounded-[20px] shadow-md mb-8 flex flex-col h-full">
              <div class="w-fit p-5">
                <img class="object-contain h-24 w-36" src="../assets/landingImages/money.svg" alt="Money bag" />
              </div>
              <h4 class="text-center font-semibold text-3xl green mb-3">
              Save money
              </h4>
              <p class="text-center text-white flex-grow">
              Design services can be expensive. Logo<span class="text-secondary">Ya</span> is affordable for small businesses.
              </p>
            </div>
          </div>
          <div class="w-full md:w-1/3 lg:w-1/3 px-4">
            <div class="glass items-center p-[20px] md:px-7 xl:px-10 rounded-[20px] shadow-md mb-8 flex flex-col h-full">
              <div class="w-fit p-5">
                <img class="object-contain h-24 w-36" src="../assets/landingImages/pencil.svg" alt="Pencil" />
              </div>
              <h4 class="text-center font-semibold text-3xl green mb-3">
              DIY
              </h4>
              <p class="text-center text-white flex-grow">
              No design skills? No problem. Easy to set up for everyone.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>    
    <!--HOW IT WORKS SECTION-->
    <section class="animate-fade-up animate-delay-700 flex justify-center pt-20 lg:pt-[80px] pb-12 lg:pb-[90px]">
      <div class="container">
        <div class="flex flex-wrap mx-4">
          <div class="w-full px-4">
            <div class="text-center mx-auto mb-12 lg:mb-20 max-w-[510px]">
              <h2 class="heading">
               Our Proccess
              </h2>
              <span class="font-semibold text-lg text-white mb-2 block">
                4 Steps to your Brand
              </span> 
            </div>
          </div>
        </div>
        <div class="flex flex-wrap mx-4 gap-y-12">
          <div class="w-full md:w-1/4 lg:w-1/4 px-4">
            <div class="glass items-center p-[20px] md:px-7 xl:px-10 rounded-[20px] shadow-md mb-8 flex flex-col h-full">
              <div class="w-fit p-5">
                <img class="object-contain h-24 w-36" src="../assets/landingImages/vision-white.svg" alt="preview eye" />
              </div>
              <h4 class="text-center font-semibold text-3xl green mb-3">
              Share Your Vision
              </h4>
              <p class="text-center text-white flex-grow">
              Answer survey to help us understand your business values and goals.
              </p>
            </div>
          </div>
          <div class="w-full md:w-1/4 lg:w-1/4 px-4">
            <div class="glass items-center p-[20px] md:px-7 xl:px-10 rounded-[20px] shadow-md mb-8 flex flex-col h-full">
              <div class="w-fit p-5">
                <img class="object-contain h-24 w-36" src="../assets/landingImages/choose-style.svg" alt="choose style" />
              </div>
              <h4 class="text-center font-semibold text-3xl green mb-3">
              Choose Your Style
              </h4>
              <p class="text-center text-white flex-grow">
              Personalize your logo and design assets to reflect your brand identity.
              </p>
            </div>
          </div>
          <div class="w-full md:w-1/4 lg:w-1/4 px-4">
            <div class="glass items-center p-[20px] md:px-7 xl:px-10 rounded-[20px] shadow-md mb-8 flex flex-col h-full">
              <div class="w-fit p-5">
                <img class="object-contain h-24 w-36" src="../assets/landingImages/branding.svg" alt="branding unleashed" />
              </div>
              <h4 class="text-center font-semibold text-3xl green mb-3">
              Branding unleashed
              </h4>
              <p class="text-center text-white flex-grow">
              Download your professional logo and branding assets instantly.
              </p>
            </div>
          </div>
          <div class="w-full md:w-1/4 lg:w-1/4 px-4">
            <div class="glass items-center p-[20px] md:px-7 xl:px-10 rounded-[20px] shadow-md mb-8 flex flex-col h-full">
              <div class="w-fit p-5">
                <img class="object-contain h-24 w-36" src="../assets/landingImages/keep-growing.svg" alt="keep growing" />
              </div>
              <h4 class="text-center font-semibold text-3xl green mb-3">
              Keep Growing
              </h4>
              <p class="text-center text-white flex-grow">
              Empower your brand with our AI-powered marketing and sales tools.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- TOOLS SECTION -->
    <section class="bg-homepagebg-tools bg-contain animate-fade-up animate-delay-700 flex justify-center pt-20 lg:pt-[80px]    pb-12 lg:pb-[90px]">
      <div class="container">
        <div class="flex flex-wrap mx-4">
          <div class="w-full px-4">
            <div class="text-center mx-auto mb-12 lg:mb-20 max-w-[510px]">
              <h2 class="heading">
                Our Tools 
              </h2>
              <span class="font-semibold text-lg text-white mb-2 block">
               Build and grow your buisness
              </span> 
            </div>
          </div>
        </div>
        <div class="flex flex-wrap mx-4 gap-y-12">
          <div class="w-full md:w-1/4 lg:w-1/4 px-4">
            <div class="glass p-[20px] md:px-2 xl:px-8 rounded-[20px] shadow-md mb-8 h-full flex flex-col items-center">
              <div class="w-fit grow">
                <img class="object-contain h-24 w-24" src="@/assets/tools/businessNameGenerator.png" alt="icon" />
              </div>
              <h4 class="text-center font-semibold text-3xl green mb-3 mt-auto grow h-[25%]">
              Business Name Generator
              </h4>
              <p class="text-center text-white grow">
              Generate unique and catchy business names with just a click.
              </p>
              <div class="mt-auto text-center">
                <router-link to="/businessnamegenerator">
                  <button class="bg-white text-gray-400 font-bold font-inter border-none rounded-md p-4 px-6 mt-4 xl:mt-2"> Available soon</button>  
                </router-link>
              </div>
            </div>
          </div>
          <div class="w-full md:w-1/4 lg:w-1/4 px-4">
            <div class="glass p-[20px] md:px-2 xl:px-8 rounded-[20px] shadow-md mb-8 h-full flex flex-col items-center">
              <div class="w-fit grow">
                <img class="object-contain h-24 w-24" src="@/assets/tools/marketingStrategyGenerator.png" alt="icon" />
              </div>
              <h4 class="text-center font-semibold text-3xl green mb-3 mt-auto grow h-[25%]">
              Marketing Strategy Generator
              </h4>
              <p class="text-center text-white grow">
              Understand your perfect customer tailored to your brand.
              </p>
              <div class="mt-auto text-center">
                <router-link to="/marketingstrategygenerator">
                  <button class="bg-white text-gray-400 font-bold font-inter border-none rounded-md p-4 px-6 mt-4 xl:mt-2"> Available soon
                  </button>  
                </router-link>
              </div>
            </div>
          </div>
          <div class="w-full md:w-1/4 lg:w-1/4 px-4">
            <div class="glass p-[20px] md:px-2 xl:px-8 rounded-[20px] shadow-md mb-8 h-full flex flex-col items-center">
              <div class="w-fit grow">
                <img class="object-contain h-24 w-24" src="@/assets/tools/businessPlanGenerator.png" alt="icon" />
              </div>
              <h4 class="text-center font-semibold text-3xl green mb-3 mt-auto grow h-[25%]">
              Business Plan Generator
              </h4>
              <p class="text-center text-white grow">
              Condense your business into a compelling one-page summary.
              </p>
              <div class="mt-auto text-center">
                <router-link to="/">
                  <button :disabled="true" class="bg-white text-gray-400 disabled font-bold font-inter border-none rounded-md p-4 px-6 mt-4 xl:mt-2"> Available soon
                  </button>  
                </router-link>
              </div>
            </div>
          </div>
          <div class="w-full md:w-1/4 lg:w-1/4 px-4">
            <div class="glass p-[20px] md:px-2 xl:px-8 rounded-[20px] shadow-md mb-8 h-full flex flex-col items-center">
              <div class="w-fit grow">
                <img class="object-contain h-24 w-24" src="@/assets/tools/brandBuilder.png" alt="icon" />
              </div>
              <h4 class="text-center font-semibold text-3xl green mb-3 mt-auto grow h-[25%]">
              Brand Builder
              </h4>
              <p class="text-center text-white grow">
              Build branding that resonate with your business in just few steps.
              </p>
              <div class="mt-auto text-center">
                <router-link to="/brandbuilder">
                  <button class="btn-green text-primary font-bold font-inter border-none rounded-md p-4 px-6 mt-5 mt-4 xl:mt-2"
                  > Try Now
                  </button>  
                </router-link>
              </div>
            </div>
          </div>
        </div>
        <router-link to="/all-tools">
          <button class="btn-green text-primary font-bold font-inter border-none rounded-md p-4 px-6 mt-12">
          Check out all tools →
          </button>  
        </router-link>
      </div>
    </section>
    <!--Waitlist-->
    <section class="w-full">
      <div class="w-11/12 lg:w-3/4 mx-auto my-8 pb-14 xl:pb-40 pt-16 text-center xl:text-left">
        <div class="flex flex-col items-center justify-center rounded-[20px] mx-auto py-14 px-8 lg:px-16 bg-gradient-to-r from-green-400 to-blue-500">
          <div class="w-full lg:w-2/3 text-white font-inter text-center">
            <h2 class="font-bold text-[32px] md:text-[40px] mb-6">
            Join our waitlist today and be among the first to experience the
            magic of Logo<span class="text-secondary">Ya</span>.
            </h2>
          </div>
          <button
          onclick="ml('show', 'PaZtC4', true)"
          href="javascript:void(0)"
          class="ml-onclick-form bg-secondary text-primary font-bold font-inter border-none rounded-md p-4 px-6 mt-5">
          Get an early access
          </button>
        </div>
      </div>
    </section>
    <!-- FAQ Section -->
    <section class="max-w-screen-xl mx-auto min-h-sceen">
      <div class="container flex flex-col mx-auto my-8 p-4 md:p-8">
        <h2 class="heading">Frequently Asked Questions</h2>
        <div class="divide-y xl:m-16 lg:px-12 xl:px-32 divide-gray-700">
          <details class="group glass m-2 p-4 text-white">
				    <summary class="flex justify-between items-center text-md lg:text-xl cursor-pointer list-none">
					    <span> What is Logo<span class="text-secondary">Ya</span>?</span>
					    <span class="transition group-open:rotate-180">
                <svg fill="none" height="24" shape-rendering="geometricPrecision" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" viewBox="0 0 24 24" width="24"><path d="M6 9l6 6 6-6"></path>
                </svg>
              </span>
				    </summary>
				    <p class="p-4 group-open:animate-fadeIn">Logo<span class="text-secondary">Ya</span> is the solution for small and medium businesses that helps with launching your business. Whole process from concept (business plan, buyer persona) through branding (logo, design assets) to growing (marketing strategy).</p>
			    </details>
          <details class="group glass m-2 p-4 text-white">
				    <summary class="flex justify-between items-center text-md lg:text-xl cursor-pointer list-none">
					    <span> Do I need any experience?</span>
					    <span class="transition group-open:rotate-180">
                <svg fill="none" height="24" shape-rendering="geometricPrecision" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" viewBox="0 0 24 24" width="24"><path d="M6 9l6 6 6-6"></path>
                </svg>
              </span>
				    </summary>
				    <p class="p-4 group-open:animate-fadeIn">
            Logo<span class="text-secondary">Ya</span> is the solution for small and medium businesses that helps with launching your business. Whole process from concept (business plan, buyer persona) through branding (logo, design assets) to growing (marketing strategy).
				    </p>
			    </details>
          <details class="group glass m-2 p-4 text-white">
				    <summary class="flex justify-between items-center text-md lg:text-xl cursor-pointer list-none">
					    <span>Is Logo<span class="text-secondary">Ya</span> free to use?</span>
					    <span class="transition group-open:rotate-180">
                <svg fill="none" height="24" shape-rendering="geometricPrecision" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" viewBox="0 0 24 24" width="24"><path d="M6 9l6 6 6-6"></path>
                </svg>
              </span>
				    </summary>
				    <p class="p-4 group-open:animate-fadeIn">Some features are 100% free - especially for beginners who would like to check out Logo<span class="text-secondary">Ya</span>. But if you are serious about building and growing your business we have special plan for you!</p>
			    </details>
          <details class="group glass m-2 p-4 text-white">
				    <summary class="flex justify-between items-center text-md lg:text-xl cursor-pointer list-none">
					    <span>What do I receive in branding package?</span>
					    <span class="transition group-open:rotate-180">
                <svg fill="none" height="24" shape-rendering="geometricPrecision" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" viewBox="0 0 24 24" width="24"><path d="M6 9l6 6 6-6"></path>
                </svg>
              </span>
				    </summary>
				    <p class="p-4 group-open:animate-fadeIn">For now you will get: logo, social media ready assets (facebook, twitter, linkedin, youtube), jpg, png, svg.</p>
			    </details>
          <details class="group glass m-2 p-4 text-white">
				    <summary class="flex justify-between items-center text-md lg:text-xl cursor-pointer list-none">
					    <span>How Logo<span class="text-secondary">Ya</span> can help me?</span>
					    <span class="transition group-open:rotate-180">
                <svg fill="none" height="24" shape-rendering="geometricPrecision" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" viewBox="0 0 24 24" width="24"><path d="M6 9l6 6 6-6"></path>
                </svg>
              </span>
				    </summary>
				    <p class="p-4 group-open:animate-fadeIn">Logo<span class="text-secondary">Ya</span> offers you the whole proccess to build the brand you want - from concept through branding to marketing.</p>
			    </details>
        </div>
      </div>
    </section>

    <!--FOOTER-->
    <Footer />

  </div>
</template>
<script>
  export default {
    name: "LogoYa Homepage",
    methods: {
      allTools() {
        this.$router.push("/all-tools");
      },
    },
  };
</script>
<!-- MailerLite Universal -->
<script>
    (function(w,d,e,u,f,l,n){w[f]=w[f]||function(){(w[f].q=w[f].q||[])
    .push(arguments);},l=d.createElement(e),l.async=1,l.src=u,
    n=d.getElementsByTagName(e)[0],n.parentNode.insertBefore(l,n);})
    (window,document,'script','https://assets.mailerlite.com/js/universal.js','ml');
    ml('account', '193462');
</script>
<!-- End MailerLite Universal -->
<style lang="">
</style>