<template lang="">
   <div class="bg-primary font-inter">
      
      <Menu />
  
      <!-- TOOLS SECTION -->
      <section class="flex justify-center pt-20 lg:pt-[120px] mt-4 pb-12 lg:pb-[90px]">
         <div class="container">
            <div class="flex flex-wrap mx-4">
               <div class="w-full px-4">
                  <div class="text-center mx-auto mb-12 lg:mb-20 max-w-[510px]">
                     <h2 class="heading">
                     Our Tools 
                     </h2>
                     <span class="font-semibold text-lg text-white mb-2 block">
                     Build and grow your buisness
                     </span>                 
                  </div>
               </div>
            </div>
            <div class="flex flex-wrap gap-y-12">
               <div class="w-full md:w-1/3 lg:w-1/3 px-4">
                  <div class="glass p-[20px] md:px-4 xl:px-8 rounded-[20px] shadow-md mb-8 h-full flex flex-col flex-grow">
                     <div class="flex items-center justify-center">
                        <img class="w-[96px]" src="@/assets/tools/businessNameGenerator.png" alt="icon" />
                     </div>
                     <h4 class="text-center font-semibold text-3xl green mb-3 mt-auto">
                     Business Name Generator
                     </h4>
                     <p class="text-center text-white">
                     Generate unique and catchy business names with just a click.
                     </p>
                     <div class="mt-auto text-center">
                        <router-link to="/businessnamegenerator">
                           <button :disabled="true" class="bg-white text-gray-400 disabled font-bold font-inter border-none rounded-md p-4 px-6 xl:mt-0"> Available soon</button>  
                        </router-link>
                     </div>
                  </div>
               </div>
               <div class="w-full md:w-1/3 lg:w-1/3 px-4">
                  <div class="glass p-[20px] md:px-7 xl:px-10 rounded-[20px] shadow-md mb-8 h-full flex flex-col flex-grow">
                     <div class="flex items-center justify-center mb-4">
                        <img class="w-[96px]" src="@/assets/tools/marketingStrategyGenerator.png" alt="icon" />
                     </div>
                     <h4 class="text-center font-semibold text-3xl green mb-3 mt-auto">
                     Marketing Strategy Generator
                     </h4>
                     <p class="text-center text-white">
                     Develop marketing plans tailored to your business goals with ease.
                     </p>
                     <div class="mt-auto text-center">
                        <router-link to="/marketingstrategygenerator">
                           <button :disabled="true" class="bg-white text-gray-400 disabled font-bold font-inter border-none rounded-md p-4 px-6 xl:mt-0"> Available soon</button>  
                        </router-link>
                     </div>
                  </div>
               </div>
               <div class="w-full md:w-1/3 lg:w-1/3 px-4">
                  <div class="glass p-[20px] md:px-7 xl:px-10 rounded-[20px] shadow-md mb-8 h-full flex flex-col flex-grow">
                     <div class="flex items-center justify-center mb-4">
                        <img class="w-[96px]" src="@/assets/tools/brandBuilder.png" alt="icon" />
                     </div>
                     <h4 class="text-center font-semibold text-3xl green mb-3 mt-auto">
                     Brand Builder
                     </h4>
                     <p class="text-center text-white">
                     Build branding that resonate with your business in just few steps
                     </p>
                     <div class="mt-auto text-center">
                        <router-link to="/brandbuilder">
                           <button class="btn-green text-primary font-bold font-inter border-none rounded-md p-4 px-6 mt-5 xl:mt-0"
                           > Try Now
                           </button>  
                        </router-link>
                     </div>
                  </div>
               </div>
               <div class="w-full md:w-1/3 lg:w-1/3 px-4">
                  <div class="glass p-[20px] md:px-7 xl:px-10 rounded-[20px] shadow-md mb-8 h-full flex flex-col flex-grow">
                     <div class="flex items-center justify-center mb-4">
                        <img class="w-[96px]" src="@/assets/tools/businessPlanGenerator.png" alt="icon" />
                     </div>
                     <h4 class="text-center font-semibold text-3xl green mb-3 mt-auto">
                     Business Plan Generator
                     </h4>
                     <p class="text-center text-white">
                     Condense your business into a compelling one-page summary.
                     </p>
                     <div class="mt-auto text-center">
                        <router-link to="/">
                           <button :disabled="true" class="bg-white text-gray-400 disabled font-bold font-inter border-none rounded-md p-4 px-6 xl:mt-0"> Available soon
                           </button>  
                        </router-link>
                     </div>
                  </div>
               </div>
               <div class="w-full md:w-1/3 lg:w-1/3 px-4">
                  <div class="glass p-[20px] md:px-7 xl:px-10 rounded-[20px] shadow-md mb-8 h-full flex flex-col flex-grow">
                     <div class="flex items-center justify-center mb-4">
                        <img class="w-[96px]" src="@/assets/tools/usp.png" alt="icon" />
                     </div>
                     <h4 class="text-center font-semibold text-3xl green mb-3 mt-auto">
                     USP Generator
                     </h4>
                     <p class="text-center text-white">
                     Create unique selling propositions to set yourself apart from competitors.
                     </p>
                     <div class="mt-auto text-center">
                        <router-link to="/">
                           <button :disabled="true" class="bg-white text-gray-400 disabled font-bold font-inter border-none rounded-md p-4 px-6 xl:mt-0"> Available soon
                           </button>  
                        </router-link>
                     </div>
                  </div>
               </div>
               <div class="w-full md:w-1/3 lg:w-1/3 px-4">
                  <div class="glass p-[20px] md:px-7 xl:px-10 rounded-[20px] shadow-md mb-8 h-full flex flex-col flex-grow">
                     <div class="flex items-center justify-center mb-4">
                        <img class="w-[96px]" src="@/assets/tools/buyerPersona.png" alt="icon" />
                     </div>
                     <h4 class="text-center font-semibold text-3xl green mb-3 mt-auto">
                     Buyer Persona Generator
                     </h4>
                     <p class="text-center text-white">
                     Understand your perfect customer tailored to your brand.
                     </p>
                     <div class="mt-auto text-center">
                        <router-link to="/">
                           <button :disabled="true" class="bg-white text-gray-400 disabled font-bold font-inter border-none rounded-md p-4 px-6 xl:mt-0"> Available soon
                           </button>  
                        </router-link>
                     </div>
                  </div>
               </div>
               <!-- <div class="w-full md:w-1/4 lg:w-1/4 px-4">
                  <div class="glass p-[20px] md:px-7 xl:px-10 rounded-[20px] shadow-md mb-8 h-full flex flex-col flex-grow">
                     <div class="flex items-center justify-center mb-4">
                        <svg width="64" height="64" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                        d="M18.6746 0.26779C10.0637 -1.33065 1.86522 4.39279 0.266779 13.0037C-0.506658 17.1803 0.421467 21.3568 2.79334 24.8631C5.21678 28.3693 8.82615 30.6897 12.9512 31.4631C13.9308 31.6178 14.859 31.7209 15.7871 31.7209C23.2637 31.7209 29.9668 26.3584 31.359 18.6756C32.9574 10.0647 27.234 1.81466 18.6746 0.26779ZM29.6574 18.3662C29.5543 18.8819 29.4512 19.449 29.2965 19.9131L16.7668 15.2209V1.81466C17.2824 1.86623 17.8496 1.91779 18.3652 2.02091C25.9449 3.4131 30.998 10.735 29.6574 18.3662ZM14.9105 1.81466V14.9115H1.86522C1.91678 14.3959 1.96834 13.8287 2.07147 13.3131C3.20584 6.86779 8.67147 2.22716 14.9105 1.81466ZM13.3121 29.6584C9.65115 28.9881 6.45428 26.9256 4.28865 23.8318C2.79334 21.7178 1.96834 19.2428 1.81365 16.7678H15.6324L28.5746 21.6147C26.064 27.3381 19.7574 30.8443 13.3121 29.6584Z"
                        fill="white"/>
                        </svg>
                     </div>
                     <h4 class="text-center font-semibold text-3xl green mb-3 mt-auto">
                     Sales Funnel Generator
                     </h4>
                     <p class="text-center text-white">
                     Easily visualize and plan the stages of your sales process.
                     </p>
                     <div class="mt-auto text-center">
                        <router-link to="/">
                           <button :disabled="true" class="bg-white text-gray-400 disabled font-bold font-inter border-none rounded-md p-4 px-6 xl:mt-0"> Available soon
                           </button>  
                        </router-link>
                     </div>
                  </div>
               </div> -->
               <div class="w-full md:w-1/3 lg:w-1/3 px-4">
                  <div class="glass p-[20px] md:px-7 xl:px-10 rounded-[20px] shadow-md mb-8 h-full flex flex-col flex-grow">
                     <div class="flex items-center justify-center mb-4">
                        <img class="w-[96px]" src="@/assets/tools/businessIdeas.png" alt="icon" />
                     </div>
                     <h4 class="text-center font-semibold text-3xl green mb-3 mt-auto">
                     Business Ideas Generator
                     </h4>
                     <p class="text-center text-white">
                     Generate business ideas based on your interests and market trends.
                     </p>
                     <div class="mt-auto text-center">
                        <router-link to="/">
                           <button :disabled="true" class="bg-white text-gray-400 disabled font-bold font-inter border-none rounded-md p-4 px-6 xl:mt-0"> Available soon
                           </button>  
                        </router-link>
                     </div>
                  </div>
               </div>
               <div class="w-full md:w-1/3 lg:w-1/3 px-4">
                  <div class="glass p-[20px] md:px-7 xl:px-10 rounded-[20px] shadow-md mb-8 h-full flex flex-col flex-grow">
                     <div class="flex items-center justify-center mb-4">
                        <img class="w-[96px]" src="@/assets/tools/leadMagnetGenerator.png" alt="icon" />
                     </div>
                     <h4 class="text-center font-semibold text-3xl green mb-3 mt-auto">
                     Lead Magnet Generator
                     </h4>
                     <p class="text-center text-white">
                     Generate compelling content ideas to attract and engage potential customers.
                     </p>
                     <div class="mt-auto text-center">
                        <router-link to="/">
                           <button :disabled="true" class="bg-white text-gray-400 disabled font-bold font-inter border-none rounded-md p-4 px-6 xl:mt-0"> Available soon
                           </button>  
                        </router-link>
                     </div>
                  </div>
               </div>
               <!-- <div class="w-full md:w-1/3 lg:w-1/3 px-4">
                  <div class="glass p-[20px] md:px-7 xl:px-10 rounded-[20px] shadow-md mb-8 h-full flex flex-col flex-grow">
                     <div class="flex items-center justify-center mb-4">
                        <svg width="64" height="64" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                        d="M18.6746 0.26779C10.0637 -1.33065 1.86522 4.39279 0.266779 13.0037C-0.506658 17.1803 0.421467 21.3568 2.79334 24.8631C5.21678 28.3693 8.82615 30.6897 12.9512 31.4631C13.9308 31.6178 14.859 31.7209 15.7871 31.7209C23.2637 31.7209 29.9668 26.3584 31.359 18.6756C32.9574 10.0647 27.234 1.81466 18.6746 0.26779ZM29.6574 18.3662C29.5543 18.8819 29.4512 19.449 29.2965 19.9131L16.7668 15.2209V1.81466C17.2824 1.86623 17.8496 1.91779 18.3652 2.02091C25.9449 3.4131 30.998 10.735 29.6574 18.3662ZM14.9105 1.81466V14.9115H1.86522C1.91678 14.3959 1.96834 13.8287 2.07147 13.3131C3.20584 6.86779 8.67147 2.22716 14.9105 1.81466ZM13.3121 29.6584C9.65115 28.9881 6.45428 26.9256 4.28865 23.8318C2.79334 21.7178 1.96834 19.2428 1.81365 16.7678H15.6324L28.5746 21.6147C26.064 27.3381 19.7574 30.8443 13.3121 29.6584Z"
                        fill="white"/>
                        </svg>
                     </div>
                     <h4 class="text-center font-semibold text-3xl green mb-3 mt-auto">
                     Paid Campaign Generator
                     </h4>
                     <p class="text-center text-white">
                     Generate targeted and efficient advertising campaigns across various platforms to maximize ROI.
                     </p>
                     <div class="mt-auto text-center">
                        <router-link to="/">
                           <button :disabled="true" class="bg-white text-gray-400 disabled font-bold font-inter border-none rounded-md p-4 px-6 xl:mt-0"> Available soon
                           </button>  
                        </router-link>
                     </div>
                  </div>
               </div> -->
               <div class="w-full md:w-1/3 lg:w-1/3 px-4">
                  <div class="glass p-[20px] md:px-7 xl:px-10 rounded-[20px] shadow-md mb-8 h-full flex flex-col flex-grow">
                     <div class="flex items-center justify-center mb-4">
                        <img class="w-[96px]" src="@/assets/tools/sloganGenerator.png" alt="icon" />
                     </div>
                     <h4 class="text-center font-semibold text-3xl green mb-3 mt-auto">
                     Slogan Generator
                     </h4>
                     <p class="text-center text-white">
                     Create memorable and impactful slogans to enhance brand identity and resonate with your audience.
                     </p>
                     <div class="mt-auto text-center">
                        <router-link to="/">
                           <button :disabled="true" class="bg-white text-gray-400 disabled font-bold font-inter border-none rounded-md p-4 px-6 xl:mt-0"> Available soon
                           </button>  
                        </router-link>
                     </div>
                  </div>
               </div>
            </div>
        </div>
      </section>

      <!--SubscribeList-->
      <section class="w-full">
         <div class="w-11/12 lg:w-4/5 mx-auto my-16 pb-16 text-center xl:text-left">
            <div class="flex flex-col items-center justify-center rounded-[20px] mx-auto py-14 px-8 lg:px-16 bg-gradient-to-r from-green-400 to-blue-500">
               <div class="w-full lg:w-2/3 text-white font-inter text-center">
                  <h2 class="font-bold text-[32px] md:text-[40px] mb-6">
                  If You would like to the first to experience our magic tools, subscribe to our newsletter.
                  </h2>
               </div>
               <button
               onclick="ml('show', 'PaZtC4', true)"
               href="javascript:void(0)"
               class="ml-onclick-form bg-secondary text-primary font-bold font-inter border-none rounded-md p-4 px-6 mt-5">
               Subscribe to get access
               </button>
            </div>
         </div>
      </section>
  
      <!--FOOTER-->
      <Footer />
   </div>
</template>

<script>
  export default {
  };
</script>
<!-- MailerLite Universal -->
<script>
    (function(w,d,e,u,f,l,n){w[f]=w[f]||function(){(w[f].q=w[f].q||[])
    .push(arguments);},l=d.createElement(e),l.async=1,l.src=u,
    n=d.getElementsByTagName(e)[0],n.parentNode.insertBefore(l,n);})
    (window,document,'script','https://assets.mailerlite.com/js/universal.js','ml');
    ml('account', '193462');
</script>
<!-- End MailerLite Universal -->
<style lang=""></style>
  